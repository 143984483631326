<template>
    <div id="hservice">
        <div class="container-fluid py-3" >
            <div class="container project-margin my-4 text-center">
                <div class="row my-5">
                    <div class="col-12">
                        <h3 class="tittle-project">Our Projects</h3>
                        <center> <hr style="border-top: 4px solid #058CFF; width: 90px; opacity: 100;"></center>
                    </div>
                </div>
                <div class="row my-3">
                    <div class="col-12">
                        <ul class="nav justify-content-center">
                            <li class="nav-item">
                                <a class="nav-link active" aria-current="page" href="#">All</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link active" aria-current="page" href="#">Visual Effect</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link active" aria-current="page" href="#">Motion Graphic</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link active" aria-current="page" href="#">3D Animation</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link active" aria-current="page" href="#">3D Archithectural</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link active" aria-current="page" href="#">Website</a>
                            </li>
                            
                        </ul>
                    </div>
                    <!-- All		|	Visual Effect		|	Motion Graphic	|	3D Animation	|	3D Archithectural		|	Website -->
                </div>
            </div>
            <div class="container my-3">
                <div class="row my-5">
                    <div class="col-12 col-md-6 col-lg-4 mt-5">
                        <div class="" >
                            <router-link :to="{ name: 'detailproject'}" class="nav-link px-0 mt-0">
                                <img src="../assets/images/project/Asset 15180.png" class="img-fluid" width="100%" alt="...">
                            </router-link>
                            <div class="ms-2 mt-1">
                                <h5 class="card-title font-ct">Winter in Tokyo</h5>
                                <p class="card-text">Motion Graphic</p>
                            </div>
                        </div>       
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mt-5">
                        <div class="" >
                            <router-link :to="{ name: 'detailproject'}" class="nav-link px-0 mt-0">
                                <img src="../assets/images/project/Asset 20180.png" class="img-fluid" width="100%" alt="...">
                            </router-link>
                            <div class="ms-2 mt-1">
                                <h5 class="card-title font-ct">Kompas</h5>
                                <p class="card-text">Motion Graphic</p>
                            </div>
                        </div>       
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mt-5">
                        <div class="" >
                            <router-link :to="{ name: 'detailproject'}" class="nav-link px-0 mt-0">
                                <img src="../assets/images/project/Asset 17180.png" class="img-fluid" width="100%" alt="...">
                            </router-link>
                            <div class="ms-2 mt-1">
                                <h5 class="card-title font-ct">Jaga Pocong</h5>
                                <p class="card-text">Visual Effect</p>
                            </div>
                        </div>       
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mt-5">
                        <div class="" >
                            <router-link :to="{ name: 'detailproject'}" class="nav-link px-0 mt-0">
                                <img src="../assets/images/project/Asset 16180.png" class="img-fluid" width="100%" alt="...">
                            </router-link>
                            <div class="ms-2 mt-1">
                                <h5 class="card-title font-ct">Big Babol AR Game</h5>
                                <p class="card-text">3D Animation</p>
                            </div>
                        </div>       
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mt-5">
                        <div class="" >
                            <router-link :to="{ name: 'detailproject'}" class="nav-link px-0 mt-0">
                                <img src="../assets/images/project/Asset 19180.png" class="img-fluid" width="100%" alt="...">
                            </router-link>
                            <div class="ms-2 mt-1">
                                <h5 class="card-title font-ct">Kurnia</h5>
                                <p class="card-text">Visual Effect</p>
                            </div>
                        </div>       
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mt-5">
                        <div class="" >
                            <router-link :to="{ name: 'detailproject'}" class="nav-link px-0 mt-0">
                                <img src="../assets/images/project/Asset 18180.png" class="img-fluid" width="100%" alt="...">
                            </router-link>
                            <div class="ms-2 mt-1">
                                <h5 class="card-title font-ct">GTN Data Center</h5>
                                <p class="card-text">Motion Graphic</p>
                            </div>
                        </div>       
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.font-ct{
    font-family: 'Avenir LT Std';
    color: #00AEEF;
    font-weight: 800;
}
.tittle-project{
    font-family: 'Avenir LT Std';
    color: #058CFF;
    font-weight: 900;
}

.project-margin{
   padding-top: 100px;
}

@media only screen and (max-width: 1000px) {
    .project-margin{
    padding-top: 95px;
    }
}
@media only screen and (max-width: 700px) {
    .project-margin{
    padding-top: 50px;
    }
}
</style>


<script>

export default {
    mounted () {
    window.scrollTo(0, 0)
  },
}
</script>
